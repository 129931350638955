@font-face {
  font-family: Ralaway-Thin;
  src: url(assets/fonts/Raleway/static/Raleway-Thin.ttf); 
}
@font-face {
  font-family: Ralaway-Regular;
  src: url(assets/fonts/Raleway/Raleway-VariableFont_wght.ttf); 
}
@font-face {
  font-family: Ralaway-Bold;
  src: url(assets/fonts/Raleway/static/Raleway-Bold.ttf); 
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.container {
  margin-top: 20px;
  width: 100% !important;
  max-width: 100% !important;
  display: inline-block;
}
 
.card.text-center.col-md-3 {
  margin: 3vw;
  padding: 0vw !important;
}

.row-cards {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  row-gap: 20px;
  column-gap: 20px;
}


.card  h4{
  color: #000;
}

.card.col img {
    object-fit: cover;
    width: 100%;
    height: 12vw;
    margin: auto;
    min-height: 200px;
}

.card p{
  color: #000;
  margin: 0vw;
}

.contenedor_precio, .contenedor_habitable, .contenedor_terreno {
  margin-block: 1vw;
}

p.precio {
  background-color: #e2e8f0;
    border-radius: 40px;
    padding: 0.3vw 1vw;
    text-align: center;
    margin: 0vw;
}

p.habitable{
  padding-left: 2vw;
}

p.terreno{
  padding-left: 2vw;
}

p.terreno {
  background-color: #e2e8f0;
    border-radius: 40px;
    padding: 0.3vw 1vw;
    text-align: center;
    margin: 0vw;
}


p.habitable {
  background-color: #e2e8f0;
  border-radius: 40px;
  padding: 0.3vw 1vw;
  text-align: center;
  margin: 0vw;
}

.conetenedor-datos {
  padding: 1vw 2vw 3vw;
}

.card h4 {
  color: #718096;
  padding: 0vw 1vw;
  margin-bottom: 0vw !important;
  float: left;
}

.card-body {
  flex: 1 1 auto;
  padding: 2rem 1rem 0rem;
}

.contenedor-img img {
  width: 50vw !important;
}

.contenedor_boton {
  text-align: center;
  width: 50%;
  margin: auto;
  background-color: #fff;
  border-radius: 40px;
  border: 1px solid #0099CC;
  padding: 0.2vw 0vw;
  margin-top: 2vw;
}

.contenedor_boton a {
  color: #0099CC !important;
  text-decoration: none !important;
}

.card{
  border: 2px solid rgba(0,0,0,.125) !important;
}

.card.col:hover {
  box-shadow: 0 8px 16px 0 rgb(0 0 0 / 20%);
} 

.col {
  float: left;
  /* margin: 10px; */
  min-height: 100px;
}

.withoutmargin {
  margin: 0 auto;
}

.col-2 {
  width: 20%;
  margin: 0 auto;
}

.col-3 {
  width: 33%;
  margin: 0 auto;
}

.col-4 {
  width: 25%;
  margin: 0 auto;
}

.col-5 {
  width: 50% !important;
  margin: 0 auto;
}

.col-4-reverse {
  width: 75%;
  margin: 0 auto;
}

.col-40-60 {
  width: 40%;
  margin: 0 auto;
}
.col-60-40 {
  width: 60%;
  margin: 0 auto;
}

.col-30-70 {
  width: 30%;
  margin: 0 auto;
}
.col-70-30 {
  width: 70%;
  margin: 0 auto;
}

.col-3-reverse {
  width: 66.6%;
  margin: 0 auto;
}

.col-2-reverse {
  width: 80%;
  margin: 0 auto;
}

.col-10 {
  width: 100%;
  margin: 0 auto;
}

.padding20{
  padding: 20px;
}
.list h3{
  font-size: 1.2rem;
}

.backBtn{
  padding: 10px;
  text-align: center;
  border: 1px solid #4e84d5;
  background: #4e84d5;
  color: #fff;
  text-decoration: none;
  display: inline-block;
}

.backBtn:hover{
  background-color: #fff;
  color: #4e84d5;
}


.row {
  display: -webkit-flex;
}

.row:before,
.row:after {
  content: " ";
  display: table;
}

@media screen and (max-width: 600px) {
  .no-display-movil{
    display: none;
  }
  h1.primary-title {
    font-size: 2rem;
  }
  .col-2,
  .col-2-reverse,
  .col-3,
  .col-3-reverse,
  .col-4,
  .col-6,
  .col-4-reverse,
  .col-5,
  .col-10 {
    width: 100% !important;
    margin: 0 auto;
  }
  .footer ul {
    display: contents !important;
  }
}

@media screen and (max-width: 959px) {
  h1.primary-title {
    font-size: 70px;
  }
  .row {
    display: inline;
  }
  .container {
    margin-left: 0px;
  }
  .col-4,
  .col-4-reverse,
  .col-3,
  .col-3-reverse,
  .col-2,
  .col-2-reverse {
    width: 50%;
    margin: 0 auto;
  }
  .col-10 {
    width: 100%;
    margin: 0 auto;
  }
  .footer ul {
    display: contents !important;
  }
}
h1.primary-title{
  font-family: Ralaway-Bold !important;
  font-size: 70px;
}

.center-text{
  text-align: center;
}

.ra {
  color: #2bb2ff;
}
.mi {
  color: #ff5b5c;
}
.co {
  color: #d9d9d9;
}