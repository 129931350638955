.flip-card {
    background-color: transparent;
    /* width: 300px; */
    height: 200px;
    /* border: 1px solid #f1f1f1; */
    perspective: 1000px; /* Remove this if you don't want the 3D effect */
  }
  
  /* This container is needed to position the front and back side */
  .flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.8s;
    transform-style: preserve-3d;
  }
  
  /* Do an horizontal flip when you move the mouse over the flip box container */
  .flip-card:hover .flip-card-inner {
    transform: rotateY(180deg);
  }
  
  /* Position the front and back side */
  .flip-card-front, .flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden; /* Safari */
    backface-visibility: hidden;
  }
  
  /* Style the front side (fallback if image is missing) */
  .flip-card-front {
    background-color: #2b264e;
    color: white;
  }
  
  /* Style the back side */
  .flip-card-back {
    background-color: dodgerblue;
    color: white;
    transform: rotateY(180deg);
  }

  .flip-card-front h1, .flip-card-back h1{
    padding: 25% 0;
  }

  .ofertas p{
    padding: 20px;
    text-align: center;
    margin-top: 30px;
  }

  .ofertas h3.description{
    text-align: center;
    color: #2aa5c5;
  }

  .descriptionIcons{
    background: #161139;
    color: #fff;
    padding: 30px;
    text-align: center;
    margin-top: 30px;
  }

  .descriptionIcons .icons{
    padding: 20px;
  }
  
  .descriptionIcons img{
    width: 80px;
  }

  .descriptionIcons h3{
    font-size: 1rem;
  }

  .descriptionIcons p{
    font-size: 1rem;
    padding: 0px;
    margin: 0px;
  }
  
